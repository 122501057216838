Lyte.Component.registerCustomPropHandler("cxProp");//No I18n


if(!window._cruxUtils){
	window._cruxUtils = {};
}

window._cruxUtils.getI18n = function(){
	var key = arguments[0];
	var args = [];
	for(var i=1; i<arguments.length; i++){
		args.push(arguments[i]);
	}
	var i18n = window._cruxUtils._cruxLocale[key] ? window._cruxUtils._cruxLocale[key] : key;
	i18n = i18n.replace(/{\d+}/g, function(template){
		return args[template.split("{")[1].split("}")[0]];
	});
	return i18n;
}

String.prototype.cruxReplaceIndex = function(index, replacement) {
  if (index >= this.length) {
      return this.valueOf();
  }
  return this.substring(0, index) + replacement + this.substring(index + 1);
}

String.prototype.cruxFindAllIndexOf = function(char){
	if(!char){
		return [];
	}
	var indexes = []
	for(var i=0;i<this.length;i++){
		if(this[i] === char){
			indexes.push(i);
		}
	}
	return indexes;
}

Array.prototype.cruxFilterBy = function(obj){
	if(!obj){
		return this;
	}
	var keys = Object.keys(obj);
	var array=[];
	for(var i=0;i<this.length;i++){
		for(var j=0;j<keys.length;j++){
			if(this[i][keys[j]] == obj[keys[j]] ){
				array.push(this[i]);
			}
		}
	}
	return array
}
Array.prototype.cruxFindIndexOfObject = function(key,value){	
	var index = -1
	if(!key || !value){
		return index;
	}
	this.forEach(function(item,ind){
		if(item[key] == value){
			index = ind;
			return;
		}
	})
	return index;
}

Lyte.Component.registerHelper("cruxGetI18n", function(){//No I18n
	return _cruxUtils.getI18n.apply(undefined, arguments);
});

Lyte.Component.registerHelper('subtract',function(param1,param2){//No I18N
	return param1 - param2;
});
Lyte.Component.registerHelper("userFieldValues",function(item , api_name , key){ //NO I18n
  var returnValue;//NO I18n
	if(typeof item[api_name]=="string"){ //NO I18n
		if(item[api_name]){
			returnValue = item[api_name];
		}
		else{
			returnValue = "";//NO I18n
		}
  }
  else if(typeof item[api_name]=="object"){ //NO I18n
		if(item[api_name]){
			if(!key){
				returnValue = item[api_name].name;
			}
			else{
				returnValue = item[api_name][key];
			}
		}
		else{
			returnValue = "";//NO I18n
		}
  }
	return returnValue;
});
Lyte.Component.registerHelper("cruxContains", function(array, item){//No I18n
		return array.indexOf(item)>-1;
});
Lyte.Component.registerHelper("userDispVal", function(field,val){//No I18n
	if(field == "profile"){
		if(val == "Administrator"){
				 return _cruxUtils.getI18n('Administrator');//No I18n
		}else if(val == "Standard"){ //No I18n
				 return _cruxUtils.getI18n('Standard');//No I18n
		}else{
			return val;
		}
	}
	else{
		return val;
	}
});

Lyte.Component.registerHelper("getSum", function() {//No I18n
	var sum = 0;
	var len = arguments.length;
	for(var i=0; i<len; i++){
		sum+=arguments[i];
	}
	return sum;
});
Lyte.Component.registerHelper("ifEquals",function(param1,param2){ //No I18n
	if(param1!=undefined && param2!=undefined){
		if(param1.toString() == param2.toString()){
			return true;
		}
	}
	return false
});

Lyte .Component.registerHelper("captialize",function(param1){ //No I18N
	if(param1 != undefined){
		return param1.toUpperCase();
	}
});

Lyte.Component.registerHelper("cruxRebrandProperty", function(property){
	return typeof RebrandLinkUtil != "undefined" ? RebrandLinkUtil.getProperty(property) : property;//No I18N
});

Lyte.Component.registerHelper('cruxEncodeHTML', function(value) {//NO I18N
	return typeof $ESAPI != "undefined" ? $ESAPI.encoder().encodeForHTML(value) : value;//No I18N
});

Lyte.Component.registerHelper('cruxEncodeURL', function(value) {//NO I18N
	return typeof $ESAPI != "undefined" ? $ESAPI.encoder().encodeForURL(value) : value;//No I18N
});

Lyte.Component.registerHelper('cruxEncodeURIComponent', function(value) {//NO I18N
	return typeof value != "undefined" ? encodeURIComponent(value) : value;//No I18N
});

Lyte.Component.registerHelper('searchIconSelect',function(){ //no i18n
	if(arguments[1]){
		return "searchLoading" //no i18n
	}else{
		if(arguments[0] == '' || arguments[0] == null ){
			return "searchIcon" //no i18n
		}else{
			return "clearField" //no i18n
		}
	}
});

Lyte.Component.registerHelper("cruxGetPhotoField", function(entityObj) { //NO I18n
	if(Object.keys(entityObj).indexOf("Record_Image") > -1) {
		var photeFileId = entityObj.Record_Image;

		if(entityObj.hasOwnProperty("Record_Image")) {
			var imageSpan = "";
			if(photeFileId)
			{
//				var imgSrc = "/crm/EntityImageAttach.do?action_module="+encodeURIComponent(module)+"&entityId="+encodeURIComponent(entityObj.id)+"&actionName=readImage&fileId="+photeFileId;//No I18n
//				imageSpan = '<span class="cruxNoteOwnerNameImg mR10 dIB vam"><img src="'+imgSrc+'"></span>';
				return "photoField"; //NO I18n
			} else {
//				if(module === "Leads" || module === "Contacts") {
//					imageSpan='<span class="cruxNoteOwnerNameImg mR10 dIB vam">'+entityObj.Full_Name.charAt(0)+'</span>';
//				}
				return "fullNameFirstChar"; //NO I18n
			}

			return imageSpan;
		}
	} else {
		return "";
	}
});

Lyte.Component.registerHelper("cruxGetFieldValue", function(module, entityObj, fieldObj, isEntityName) { //NO I18n
	var fieldData;

	if(fieldObj) {
		fieldData = entityObj[fieldObj.api_name];
	}

	if(!fieldData) {
		return "";
	}

	var dataType = fieldObj.data_type;

	if(dataType === "formula") {
		dataType = fieldObj.formula.return_type;
	}

	if(dataType.indexOf("lookup") > -1) {
		return fieldData.name;
	} else if(dataType === "datetime") {
		var dt;
		if(fieldObj.api_name) {
			var dateTime = fieldData.replace(/[+-]\d{2}:\d{2}/,'');
			dt = new Date(dateTime);
		} else {
			dt = new Date(fieldData);
		}
		return  Utils.getDateTimeInUserFormat(dt,true);
	} else if(dataType === "date") {
		var dt = new Date(fieldData+" 00:00:00");
		if(isNaN(dt.getTime())) {
			dt = new Date(fieldData);
		}
		return Utils.getDateInUserDatePattern(dt,true);
	} else if(dataType === "multiselectpicklist") {
		return fieldData.join(";");
	} else if(isEntityName) {

		if(module === crmModuleConstants.Contacts || module === crmModuleConstants.Leads) {
			// var fullName = entityObj.Salutation ? entityObj.Salutation + " " : "";
			// var fullName += entityObj.Full_Name;
			fieldData= entityObj.Full_Name;
		}

//		fieldData='<a  data-cid=\'detailView\' data-params=\'{"lookback":"true","module":"'+crmLookupBusinessCard.moduleName+'","id":"'+crmLookupBusinessCard.entityId+'"}\' href="/crm/EntityInfo.do?module='+encodeURIComponent(crmLookupBusinessCard.moduleName)+'&id='+encodeURIComponent(crmLookupBusinessCard.entityId)+'" onClick="Crm.trackSpotLightAction(\'Lookup Hover Click\',{\'Module\':\''+crmLookupBusinessCard.moduleName+'\'})">'+$ESAPI.encoder().encodeForHTML(fieldData)+'</a>'
		return fieldData;
	} else if(fieldObj.api_name == "Layout") {
		return fieldData.name;
	} else {
		return fieldData.toString();
	}
});

Lyte.Component.registerHelper("cruxGetEventStart_EndTime", function(entityObj, startFldObj, endFldObj) { //NO I18n
	var startDateTime= entityObj[startFldObj.api_name];
	var endDateTime= entityObj[endFldObj.api_name];

	var startDate=new Date(startDateTime);
	var endDate=new Date(endDateTime);

	if(!entityObj.All_day) {
		return Utils.getDateTimeInUserFormat(startDate,true) + ' - ' + Utils.getDateTimeInUserFormat(endDate,true);
	}

	if(startDate.getUTCDate() == endDate.getUTCDate() && startDate.getUTCMonth() == endDate.getUTCMonth() && startDate.getUTCFullYear()==endDate.getUTCFullYear()) {
		startDate = new Date(startDate.getUTCFullYear(),startDate.getUTCMonth(),startDate.getUTCDate());
		return Utils.getDateInUserDatePattern(startDate,true);
	}

	endDate = new Date(endDate.getUTCFullYear(),endDate.getUTCMonth(),endDate.getUTCDate());

	return Utils.getDateInUserDatePattern(startDate,true) + " - " + Utils.getDateInUserDatePattern(endDate,true);
});

Lyte.Component.registerHelper("cruxGetAlldayEvent", function(entityObj, bcFields) { //NO I18n
	if(entityObj.All_day){
		var fieldObj_AllDay = bcFields.filter(function (field){
			return field.api_name === "All_day"; //NO I18N
		});

		return fieldObj_AllDay[0].field_label;

	}
	return "";
});

Lyte.Component.registerHelper("cruxReplace", function(str, search, replacement){//No I18n
	if(!str){
		return "";
	}
	return str.replace(new RegExp(search, "g"), replacement);
});

Lyte.Component.registerHelper('cruxGetCrmBasePath', function() {//NO I18N
	return Crm.getCrmBasePath();
});
Lyte.Component.registerHelper('cruxShortFileNameLength', function(fileName, length) {//NO I18N
	if(fileName.length > length) {
		return fileName.substr(0, length - 3) + "...";
	} else {
		return fileName;
	}
});
Lyte.Component.registerHelper('cruxSubstr', function(text, length) {//NO I18N
	if(text.length > length) {
		return text.substr(0, length) + "...";
	} else {
		return text;
	}
});
Lyte.Component.registerHelper('cruxIfGt', function(num1, num2) {//NO I18N
	return num1 > num2;
});
Lyte.Component.registerHelper('cruxHasProperty', function(obj,prop){//No I18N
	if(obj){
		return obj.hasOwnProperty(prop);
	}
	return false;
})

Lyte.Component.registerHelper("cruxReplaceSpace", function(str){//No I18n
	return str.replace(/ /g, "&nbsp;");
});
Lyte.Component.registerHelper('cruxStringSeg', function(text, startPos, endPos) {//NO I18N
	if(text) {
		var substr = text.substring(startPos, typeof endPos === "number" ? endPos : text.length);
		return substr;
	}
	return "";
});

Lyte.Component.registerHelper("cruxGetItemAtIndex", function(array, index) { //NO I18N
	return array[index];
});

Lyte.Component.registerHelper("cruxClone", function(data) { //NO I18N
	return Lyte.deepCopyObject(data);
});
Lyte.Component.registerHelper("cruxIncrement", function(data) { //NO I18N
	var val = this.getData(data);
	this.setData(data,val+=1);
	return val;
});
Lyte.Component.registerHelper("cruxGetTagColorIndex", function(color,colorObject) { //NO I18N
	if(!colorObject){
		colorObject = {Col1: "#F17574",Col2: "#F48435",Col3: "#E7A826",Col4: "#A8C026",Col5: "#63C57E",Col6: "#1DB9B4",Col7: "#57B1FD",Col8: "#879BFC",Col9: "#D297EE",Col10: "#FD87BD",Col11: "#969696",Col12: "#658BA8",Col13: "#B88562"}//no i18n
	}
	var col = Object.keys(colorObject).filter(function(item){return colorObject[item] == color})
	return col.length ? col[0] : 'Col0'
});
Lyte.Component.registerHelper("cruxArithResult", function(){//No I18n
	return new Function('return '+Array.from(arguments).join(''))();//No I18n
})

Lyte.Component.registerHelper("cruxAnd", function(){//No I18N
	for(var i=0;i<arguments.length;i++){
		if(! arguments[i]){
			return false;
		}
	}
	return true;
})

Lyte.Component.registerHelper("cruxMaskValue", function(value, properties){//No I18n
	var res = "";
	for(var i=0; i<properties.length && res.length < value.length; i++){
		res+=properties.character;
	}
	if(properties.reverse){
		res=value.substring(0, value.length-properties.length)+res;
	}
	else{
		res+=value.substring(properties.length);
	}
	return res;
});
Lyte.Component.registerHelper('cruxGetPicklistFontColor',function(colourCode){ //no i18n
	if(colourCode && colourCode !== "" && colourCode.indexOf("#") === 0){
		colourCode = colourCode.substring(1);
		var c_r = parseInt(colourCode.substr(0, 2), 16);
		var c_g = parseInt(colourCode.substr(2, 2), 16);
		var c_b = parseInt(colourCode.substr(4, 2), 16);
		var brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000;
		if(brightness < 175){
			return "white"; //NO I18N
		}
	}
	return "black"; //NO I18N
});
Lyte.Component.registerHelper("cruxOr", function(){//No I18n
	for(var i=0; i<arguments.length; i++){
		if(arguments[i]){
			return true;
		}
	}
	return false;
});
Lyte.Component.registerHelper("cruxLookupFilterComparator",function(field,cxPropFormulaUitypes,cxPropContent){//no i18n
	if(cxPropContent[field.ui_type]){
		return cxPropContent[field.ui_type];
	}
	else if(cxPropFormulaUitypes[field.ui_type]){
		if(field.formula && field.formula.return_type && cxPropFormulaUitypes[field.ui_type][field.formula.return_type]){
			return cxPropContent[cxPropFormulaUitypes[field.ui_type][field.formula.return_type]]
		}else if(field.rollup_summary && field.rollup_summary.return_type && cxPropFormulaUitypes[field.ui_type][field.rollup_summary.return_type]){
			return cxPropContent[cxPropFormulaUitypes[field.ui_type][field.rollup_summary.return_type]]
		}else{
			return cxPropContent[0];
		}
	}else{
		return cxPropContent[0];
	}
});
